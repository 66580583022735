import React from "react"

import styles from "./target-audience.module.scss"
import TargetAudienceCard from "./target-audience-card"

const TargetAudienceComponent = ({ title, childCare, languageSchool, sportSchool, beautySchool, id }) => (
  <div id={id} className={`container ${styles.target_audience}`}>
    <h2 className={styles.title}>{title || "Параплан - российское решение для направлений"}</h2>
    <div className={styles.itemList}>
      <TargetAudienceCard
        link="/landing/education/"
        title={childCare || "Обучение"}
        image={require("../../../../images/target-audience/education.png")}
        backgroundColor={"#F2A819"} />
      <TargetAudienceCard
        link="/landing/beauty/"
        title={beautySchool || "Beauty бизнес"}
        image={require("../../../../images/target-audience/beauty.png")}
        backgroundColor={"#E75D49"} />
      <TargetAudienceCard
        link="/landing/sports/"
        title={languageSchool || "Спорт"}
        image={require("../../../../images/target-audience/sports.png")}
        backgroundColor={"#03C4A4"} />
      <TargetAudienceCard
        link="/landing/creativeschool/"
        title={sportSchool || "Творчество"}
        image={require("../../../../images/target-audience/creation.png")}
        backgroundColor={"#319DED"} />
    </div>
  </div>
)

export default TargetAudienceComponent
