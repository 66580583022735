import React from "react";
import "./screenshot-modal.scss";

const screenshotModal = ({active, setActive, children}) => {
  return (
    <div className={active ? 'modal active' : 'modal'} onClick={() => {
      document.documentElement.classList.remove("modal-open");setActive(false)}}>
      <div className={active ? 'modal__content active' : 'modal__content'} onClick={e => e.stopPropagation()}>
          <a className={'modalclose'}
                  onClick={() => {
                    document.documentElement.classList.remove("modal-open");
                    setActive(false)}}>
            <img className="imgclose" alt='' src={require("../../../images/close.svg")}/>
          </a>
        {children}
      </div>
    </div>
  );
};
export default screenshotModal;