import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroFeaturePage from "../components/pages/shared/hero/index-page"
import TargetAudienceComponent from "../components/pages/main/target-audience"
import PricingComponent from "../components/pages/main/detail-pricing"
import ContactFormComponent from "../components/pages/shared/contact-form"
import Discount from "../components/pages/main/discount"
import GalleryComponent from "../components/pages/features/tabs-gallery"
import MainPageImfo from "../components/pages/main/info"
import Banner from "../components/pages/main/banner"
import Cards from "../components/pages/main/cards"

const IndexPage = (props) => (
  <Layout page={"index"}>
    <SEO
      title="CRM-система учета для детских центров | Программа учета клиентов — Параплан"
      keywords={[`crm для детского клуба`, `crm для детского сада`, `crm для детского сада`, `crm для детского центра`, `программа для детского клуба`, `программа для детского центра`, `учет детского центра`, `учет детского клуба`]}
      description="Параплан CRM - облачная CRM система для учета и автоматизации работы вашего учебного центра, детского клуба, танцевальной студии, языкового центра, спортивной школы и франшиз. Неограниченное количество учеников и пользователей."
      url={"https://paraplancrm.ru" + props.location.pathname}
    />
    <HeroFeaturePage
      title='CRM-система учета'
      description="для полного ведения бизнеса"
      idUnderBlock="#audience"
      offsetHeader={80}
      list={[
        '<b>Автоматизирует</b> процессы: от отчетности до приема оплат.',
        'Увеличивает <b>доходы</b>.',
        'Ускоряет <b>темп работы</b> команды. ',
        'Помогает <b>контролировать сотрудников</b>.',
      ]} />
    <TargetAudienceComponent id="audience" />
    <GalleryComponent
      offShadow={true}
      title={"В системе есть все, что вам нужно"}
      subtitle={"А не только инструменты для работы с заявками и контроля отдела продаж"}
      galleryView={"img"}
      rightImage={true}
      data={[
        {
          name: "management",
          title: "Управление клиентами",
          image: require("../images/main/tabs/item-1.png"),
        },
        {
          name: "Accounting",
          title: "Учет посещаемости",
          image: require("../images/main/tabs/item-2.png"),
        },
        {
          name: "sales",
          title: "Воронка продаж",
          image: require("../images/main/tabs/item-3.png"),
        },
        {
          name: "payroll",
          title: "Расчет зарплат",
          image: require("../images/main/tabs/item-4.png"),
        },
        {
          name: "taskManager",
          title: "Планировщик задач",
          image: require("../images/main/tabs/item-5.png"),
        },
        {
          name: "dataAnalytics",
          title: "Аналитика данных",
          image: require("../images/main/tabs/item-6.png"),
        },
        {
          name: "mobileVersion",
          title: "Мобильная версия",
          image: require("../images/main/tabs/item-7.png"),
        },
        {
          name: "personalAccounts",
          title: "Личные кабинеты",
          image: require("../images/main/tabs/item-8.png"),
        },
        {
          name: "integration",
          title: "Интеграции",
          image: require("../images/main/tabs/item-9.png"),
        },
      ]}
    />
    <Discount />
    <MainPageImfo
      title='Работа с CRM - это переход на новый уровень'
      subtitle='Параплан CRM создан специально для учета клиентов и продаж в детских и образовательных центрах, салонах красоты, спортивных клубах, фитнес-центрах, танцевальных студиях и в творческих мастерских' />
    <Banner />
    <Cards />
    {/*<FeaturesComponent title='Ваш бизнес способен на большее вместе с&nbsp;Параплан:'/>
    <EmailChecklist/>
    <CustomersComponent/>*/}
    <PricingComponent title='Тарифы' mainPage={true} mobileMenu={false} />
    <ContactFormComponent />
  </Layout>
)

export default IndexPage
