import React, { useState } from "react"
import styles from "./gallery.module.scss"
import AnimateHeight from "react-animate-height"
import ScreenshotModal from "../../screenshot-modal"

const GalleryComponent = ({ title, subtitle, data, offShadow, rightImage, smallMargin }) => {
  const [tab, setTab] = useState(data[0])
  const [modalActive, setModalActive] = useState(false)
  const [modalImage, setModalImage] = useState(null)
  return <div className={`${styles.gallery} container`}>
    <div className={styles.header}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>

    <ScreenshotModal active={modalActive} setActive={setModalActive}>
      <img src={modalImage} alt='' />
    </ScreenshotModal>
    <div className={`${styles.galleryContainer} ${rightImage === true ? styles.rightImage : ''} ${smallMargin === true ? styles.smallMargin : ''}`}>
      <div className={`${styles.image} ${offShadow === true ? styles.offShadow : ''}`}>
        <img onClick={() => {
          document.documentElement.classList.add("modal-open");
          setModalActive(true);
          setModalImage(tab.image);
        }} src={tab.image} alt={tab.title} />
      </div>
      <div className={styles.tabs}>
        {data.map(t =>
          <div key={t.name}
            className={`${styles.tab} ${t.name === tab.name ? styles.open : ""}`}
            onClick={() => setTab(t)
            }>
            <div className={styles.info}>
              <div className={styles.head}>
                <div className={styles.title}>{t.title}</div>
                <div className={styles.chevron}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#6D20D2"
                      fillRule="evenodd"
                      d="M4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    ></path>
                    <path
                      fill="#6D20D2"
                      fillRule="evenodd"
                      d="M11.293 4.293a1 1 0 011.414 0l7 7a1 1 0 010 1.414l-7 7a1 1 0 01-1.414-1.414L17.586 12l-6.293-6.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <AnimateHeight duration={300} height={t.name === tab.name ? "auto" : 0}>
                <div className={`${styles.contentImage} ${offShadow === true ? styles.offShadow : ''} `}>
                  <img src={t.image} alt={t.title} onClick={() => {
                    document.documentElement.classList.add("modal-open");
                    setModalActive(true);
                    setModalImage(t.image);
                  }} src={t.image} alt={t.title}/>
                </div>
              </AnimateHeight>
            </div>
          </div>,
        )}
      </div>
    </div>
  </div>
}

export default GalleryComponent
